<template>
  <app-modal
    :title="$t('modal.media_upload_header')"
    modal-size="modal-lg"
    :show-close-button="!uploading"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        @click="confirmUploadModal"
        type="button"
        :class="`btn btn-green ${confirmButtonDisabled ? 'btn-green--disabled' : ''}`"
        data-dismiss="modal"
        :disabled="confirmButtonDisabled"
        data-test="complete_upload"
      >
        <i class="fa fa-check"></i> {{ confirmButtonLabel }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <FailedUploadInfoPanel
        v-model="tryUploadFilesAgain"
        :failed-uploaded-files-ignored="failedUploadedFilesIgnored"
        :failed-uploaded-files="failedUploadedFiles"
        :already-existing-files="alreadyExistingFiles"
      />
      <UploadProgressbar
        ref="uploadProgressbar"
        :files-to-load-length="files.length"
        :files-to-upload-length="loadedFiles.length"
        @toggle-upploading="uploading = $event"
      />
      <div class="row">
        <MetadataForm
          ref="metadataForm"
          :displayed-files="displayedFiles"
          :updating-metadata-of-existing-files="updatingMetadataOfExistingFiles"
          :force-inputs-update="forceInputsUpdate"
          @remove-file="removeFile"
        />
        <div class="col-lg-3" v-if="!isPdf && !updatingMetadataOfExistingFiles && displayedFiles.length > 1">
          <div>
            <strong>{{ $t('media.predetermined_values') }}</strong>
            <small
              v-if="defaultDescription.length > 500"
              class="form-control-feedback text-warning float-right"
            >
              <i class="fa fa-exclamation-triangle"></i>
              {{ $t('media.title_is_too_long') }}
            </small>
            <app-textarea
              v-model.trim="defaultDescription"
              :label="$t('dam.description')"
              id="media-caption-default"
            >
              <Tooltip
                :title="$t('dam.headline_seo_info')"
                icon="fab fa-google"
                customInfoClass="seo"
              />
            </app-textarea>
            <app-input
              v-model.trim="defaultImageAltText"
              :label="$t('dam.imageAltText')"
              id="media-imageAltText-default"
            />
            <AuthorSearchSelect
              v-model.trim="defaultAuthor"
              :required="false"
              id="media-author-default"
            />
            <app-textarea
              v-model.trim="defaultKeywords"
              :label="$t('modal.keywords')"
              id="media-keywords-default"
            >
            </app-textarea>
            <button
              class="btn btn-danger"
              @click="removeTitles"
            >
              {{ $t('media.remove_all_titles') }}
            </button>
            <br>
            <button
              class="btn btn-info m-t-10"
              @click="setPredeterminedTitles"
            >
              {{ $t('media.set_predetermined_titles') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import moment from 'moment'
import ExifReader from 'exifreader'
import { mapGetters } from 'vuex'
import { SOURCE_DAM_PDF } from '@/model/ValueObject/DamUploadSources'
import { DAM_CATEGORY_DEFAULT } from '@/components/mixins/valueObject/DamPdfCategoryMixin'
import { PDFDocument } from 'pdf-lib'
import Config from '@/config'
import DamApi from '@/api/dam'
import Modal from '@/components/shared/Modal'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Tooltip from '@/components/tooltip/Tooltip'
import MetadataForm from '@/components/dam/damUploadModal/MetadataForm'
import UploadProgressbar from '@/components/dam/damUploadModal/UploadProgressbar'
import FailedUploadInfoPanel from '@/components/dam/damUploadModal/FailedUploadInfoPanel'
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import MediaService from '@/services/media/MediaService'
import ErrorHandlingService from '@/services/ErrorHandlingService'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'DamUploadModal',
  props: {
    files: {
      type: Array,
      required: true
    },
    uploadSource: {
      type: String,
      required: true
    },
    onlySaveMetadata: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      loadedFiles: [],
      uploading: false,
      uploadedFiles: [],
      uploadedUuids: {},
      alreadyExistingFiles: [],
      updatingMetadataOfExistingFiles: false,
      defaultDescription: '',
      defaultImageAltText: '',
      defaultAuthor: '',
      defaultKeywords: '',
      forceInputsUpdate: 0,
      tryUploadFilesAgain: false,
      failedUploadedFilesIgnored: false,
      failedUploadedFiles: []
    }
  },
  components: {
    FailedUploadInfoPanel,
    AuthorSearchSelect,
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    MetadataForm,
    UploadProgressbar,
    Tooltip
  },
  computed: {
    ...mapGetters('dam', ['isPdf']),
    ...mapGetters(['vlm']),
    displayedFiles () {
      return this.updatingMetadataOfExistingFiles ? this.alreadyExistingFiles : this.loadedFiles
    },
    confirmButtonDisabled () {
      return this.uploading
    },
    confirmButtonLabel () {
      return this.tryUploadFilesAgain && !this.updatingMetadataOfExistingFiles ? this.$t('media.try_again') : this.$t('modal.done')
    },
    imageSettings () {
      return this.$store.getters['media/imageSettings']
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    generateThumbnail (file, size) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        throw new Error('Context not available')
      }
      return new Promise((resolve, reject) => {
        const revokeImageObject = (image) => URL.revokeObjectURL(image.src) // this is important to free up RAM memory
        const img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          const scaleRatio = size / Math.max(img.width, img.height)
          canvas.width = img.width * scaleRatio
          canvas.height = img.height * scaleRatio
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          const result = resolve(canvas.toDataURL(file.type))
          revokeImageObject(img)
          return result
        }
        img.onerror = error => {
          revokeImageObject(img)
          console.error(error)
          NotifyService.setErrorMessage(error)
          reject(error)
        }
      })
    },
    async loadPdfFile (file) {
      this.$refs.uploadProgressbar.increaseLoadingProcess()
      const base64 = await MediaService.toBase64(file)
      const pdfDoc = await PDFDocument.load(base64, {
        updateMetadata: false
      })
      return {
        headline: file.name,
        caption: pdfDoc.getTitle(),
        author: pdfDoc.getAuthor(),
        keywords: pdfDoc.getKeywords(),
        categoryCode: DAM_CATEGORY_DEFAULT,
        originalFile: file,
        base64
      }
    },
    async loadFile (file) {
      const tags = await ExifReader.load(file)
      let keywords
      if (Array.isArray(tags.Keywords)) {
        keywords = tags.Keywords.map(k => k.description).join(',')
      } else {
        keywords = tags.Keywords?.description
      }
      const author = tags['By-line'] || tags['by-line'] || tags.Author || tags.author || tags.Creator || tags.creator || tags.Artist || tags.artist || tags.Credit || tags.credit
      const imagePath = await this.generateThumbnail(file, 500)
      this.$refs.uploadProgressbar.increaseLoadingProcess()
      return {
        description: tags.description?.description,
        author: author?.description,
        keywords,
        source: tags.Source?.description,
        siteLocks: [],
        imagePath,
        originalFile: file
      }
    },
    async processFilesInChunks (files, fileProcessCallback) {
      this.failedUploadedFiles = []
      const processedFiles = []
      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < files.length; i += chunkSize) {
        const chunkFiles = files.slice(i, i + chunkSize)
        const promises = chunkFiles.map(fileProcessCallback)
        processedFiles.push(...await Promise.all(promises))
      }
      return processedFiles
    },
    setFinalInputValues (loadedFile) {
      loadedFile.description = loadedFile.description || this.defaultDescription
      loadedFile.imageAltText = loadedFile.imageAltText || this.defaultImageAltText
      loadedFile.author = loadedFile.author || this.defaultAuthor
      loadedFile.keywords = loadedFile.keywords || this.defaultKeywords
    },
    readFileAndUpload (loadedFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (event) => {
          this.setFinalInputValues(loadedFile)
          const uploadFunction = this.isPdf ? this.uploadPdf : this.uploadImage
          resolve(uploadFunction(event.target.result, loadedFile))
        }
        reader.readAsDataURL(loadedFile.originalFile)
      })
    },
    prepareBodyFormDataForPdfUpload (pdf, loadedFile) {
      const bodyFormData = new FormData()
      const fileName = loadedFile.originalFile.name
      bodyFormData.set('uploadSource', SOURCE_DAM_PDF)
      bodyFormData.set('headline', loadedFile.headline)
      if (loadedFile.caption) {
        bodyFormData.set('caption', loadedFile.caption)
      }
      bodyFormData.set('author', loadedFile.author)
      bodyFormData.set('keywords', loadedFile.keywords)
      bodyFormData.set('categoryCode', loadedFile.categoryCode)
      bodyFormData.set('pdf', MediaService.dataUriToFile(pdf, fileName))
      return bodyFormData
    },
    prepareBodyFormDataForImageUpload (image, loadedFile) {
      const bodyFormData = new FormData()
      const fileName = loadedFile.originalFile.name
      bodyFormData.set('uploadSource', this.uploadSource)
      bodyFormData.set('unlockAfterPublish', true)
      bodyFormData.set('maxCropWidth', this.imageSettings.maxCropWidth)
      bodyFormData.set('maxCropHeight', this.imageSettings.maxCropHeight)
      bodyFormData.set('imageAltText', loadedFile.imageAltText)
      bodyFormData.set('description', loadedFile.description)
      bodyFormData.set('author', loadedFile.author)
      if (loadedFile.source) {
        bodyFormData.set('source', loadedFile.source)
      }
      bodyFormData.set('keywords', loadedFile.keywords)
      loadedFile.siteLocks.forEach((siteLock) => {
        bodyFormData.append('siteLocks[]', siteLock)
      })
      bodyFormData.set('image', MediaService.dataUriToFile(image, fileName))
      return bodyFormData
    },
    uploadPdf (pdf, loadedFile) {
      const fileName = loadedFile.originalFile.name
      const bodyFormData = this.prepareBodyFormDataForPdfUpload(pdf, loadedFile)
      return DamApi(Config.dam.apiUploadTimeout, fileName).post('/pdf', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(async () => {})
        .catch(error => {
          this.failedUploadedFiles.push(loadedFile.originalFile)
          console.error(`Uploading of '${fileName}' to DAM failed.`)
          console.error(error)
          const { message, stack } = error
          ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /pdf', payload: bodyFormData, filename: fileName })
        })
        .finally(() => {
          this.$refs.uploadProgressbar.increaseUploadingProcess()
        })
    },
    uploadImage (image, loadedFile) {
      const fileName = loadedFile.originalFile.name
      const bodyFormData = this.prepareBodyFormDataForImageUpload(image, loadedFile)
      return DamApi(Config.dam.apiUploadTimeout, fileName).post('/image', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(async (response) => {
          const { imageAsset, uploadedImageAsset, wasCreated } = response.data
          if (!this.uploadedUuids[imageAsset.entityUuid]) {
            // check for duplicates and prevent processing the same image twice
            this.uploadedUuids[imageAsset.entityUuid] = true
            const uploadedFile = MediaService.convertToUploadedFile({
              imageAsset,
              uploadedImageAsset,
              wasCreated,
              fileBase64: image,
              fileName
            })

            const { dateTimeOriginal } = imageAsset
            if (dateTimeOriginal) {
              const date = new Date(dateTimeOriginal)
              const userTimezoneOffset = date.getTimezoneOffset() * 60000
              const correctedTimeZone = new Date(date.getTime() + userTimezoneOffset)
              uploadedFile.dateTimeOriginal = moment(correctedTimeZone, 'YYYY:MM:DD hh:mm:ss').toISOString()
            }
            if (wasCreated) {
              this.uploadedFiles.push(uploadedFile)
            } else {
              this.uploadedFiles.push(uploadedFile) // store also in 'uploadedFiles' to keep the files in order
              this.alreadyExistingFiles.push(uploadedFile)
            }
          }
        })
        .catch(error => {
          this.failedUploadedFiles.push(loadedFile.originalFile)
          console.error(`Uploading of '${fileName}' to DAM failed.`)
          console.error(error)
          const { message, stack } = error
          ErrorHandlingService.vueErrorHandler({ message, stack }, this, { url: 'POST /image', payload: bodyFormData, filename: fileName })
        })
        .finally(() => {
          this.$refs.uploadProgressbar.increaseUploadingProcess()
        })
    },
    isFormValid () {
      if (this.isPdf) {
        return true
      }
      this.$refs.metadataForm.$v.$touch()
      if (this.defaultDescription && this.defaultAuthor && this.defaultImageAltText && this.defaultKeywords) {
        return true
      }
      let isValid = true
      for (const loadedFile of this.displayedFiles) {
        if (!loadedFile.description && !this.defaultDescription) {
          isValid = false
        }
        if (this.vlm && !loadedFile.imageAltText && !this.defaultImageAltText) {
          isValid = false
        }
        if (!loadedFile.author && !this.defaultAuthor) {
          isValid = false
        }
        if (!loadedFile.keywords && !this.defaultKeywords) {
          isValid = false
        }
      }

      if (!isValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      }
      return isValid
    },
    async confirmSaveMetadata () {
      if (this.onlySaveMetadata) {
        const updatedMedia = await this.saveBulkMetadata(this.uploadedFiles)
        if (updatedMedia) {
          this.$emit('set-media', updatedMedia)
          this.close()
        }
      } else if (this.updatingMetadataOfExistingFiles) {
        const updatedMedia = await this.saveBulkMetadata(this.alreadyExistingFiles)
        if (updatedMedia) {
          // ignore updatedMedia and emit all the uploaded files (they also include the 'alreadyExistingFiles')
          this.$emit('set-media', this.uploadedFiles)
          this.close()
        }
      }
    },
    filterFailedFiles (loadedFiles) {
      const failedFilesNames = this.failedUploadedFiles.reduce((acc, failedFile) => {
        acc[failedFile.name] = true
        return acc
      }, {})
      return loadedFiles.filter(loadedFile => failedFilesNames[loadedFile.originalFile.name])
    },
    uploadFinished () { // this is also called from DamUrlButton.vue
      this.$refs.uploadProgressbar.uploadFinished()
    },
    async confirmUploadModal () {
      if (this.onlySaveMetadata || this.updatingMetadataOfExistingFiles) {
        await this.confirmSaveMetadata()
        return
      }

      if (this.failedUploadedFiles.length > 0 && !this.tryUploadFilesAgain) {
        this.failedUploadedFilesIgnored = true
      } else {
        if (!this.isFormValid()) {
          return
        }
        this.$refs.uploadProgressbar.uploadStarted()
        await this.processFilesInChunks(this.loadedFiles, this.readFileAndUpload)
        if (this.failedUploadedFiles.length > 0) {
          this.tryUploadFilesAgain = true
          this.loadedFiles = this.filterFailedFiles(this.loadedFiles)
        }
        this.uploadFinished()
      }

      if (this.failedUploadedFiles.length === 0 || !this.tryUploadFilesAgain) {
        if (this.alreadyExistingFiles.length === 0) {
          this.$emit('set-media', this.uploadedFiles)
          this.close()
          NotifyService.setSuccessMessage(this.$t('modal.uploading_done'))
        } else {
          this.updatingMetadataOfExistingFiles = true
        }
      }
    },
    async saveBulkMetadata (files) {
      if (!this.isFormValid()) {
        return
      }
      this.failedUploadedFilesIgnored = true
      this.$refs.uploadProgressbar.uploadStarted()
      const multiMetadata = files.map(file => {
        const payload = {
          entityUUid: file.entityUuid,
          description: file.description,
          imageAltText: file.imageAltText,
          author: file.author ?? file.byLine,
          keywords: file.keywords,
          siteLocks: file.siteLocks,
          source: file.source,
          headline: file.headline,
          categoryCode: file.categoryCode
        }
        if (file.dateTimeOriginal) {
          payload.dateTimeOriginal = file.dateTimeOriginal
        }
        return payload
      })
      let response
      if (this.isPdf) {
        // always a single pdf file from url
        response = await this.$store.dispatch('dam/updateMetadata', multiMetadata[0])
      } else {
        response = await this.$store.dispatch('dam/updateMetadataBulkMulti', multiMetadata)
      }
      this.uploadFinished()
      const error = this.$store.getters['dam/error']
      if (error === null) {
        NotifyService.setSuccessMessage(this.$t('modal.uploading_done'))
        return response.data
      } else {
        console.error(error)
        NotifyService.setErrorMessage(error)
        return undefined
      }
    },
    removeTitles () {
      this.loadedFiles.forEach(loadedFile => {
        loadedFile.description = ''
      })
    },
    setPredeterminedTitles () {
      this.loadedFiles.forEach(loadedFile => {
        loadedFile.description = this.defaultDescription
        loadedFile.imageAltText = this.defaultImageAltText
        loadedFile.author = this.defaultAuthor
        loadedFile.keywords = this.defaultKeywords
      })
      this.forceInputsUpdate += 1
    },
    removeFile (index) {
      this.loadedFiles.splice(index, 1)
    }
  },
  async mounted () {
    if (this.onlySaveMetadata) {
      this.loadedFiles = this.files
      this.uploadedFiles = this.files
    } else {
      await this.$refs.uploadProgressbar.loadingStarted()
      this.loadedFiles = await this.processFilesInChunks(this.files, this.isPdf ? this.loadPdfFile : this.loadFile)
      this.$refs.uploadProgressbar.loadingFinished()
    }
  }
}
</script>

<style scoped lang="scss">
.btn-green--disabled, .btn-green--disabled:hover {
  color: lighten(#465674, 20%);
  background-color: lighten(#D1DBE4, 5%);
  cursor: default !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
</style>
