<template>
  <div :class="{'col-lg-9': displayedFiles.length > 1, 'col-lg-12': displayedFiles.length <= 1}">
    <div v-for="(displayedFile, index) in displayedFiles" :key="`item-${index}-${displayedFile.metadataChosen}`">
      <div v-if="showExistingImagePanel(displayedFile)" class="row">
        <div class="col-3" />
        <UploadExistingImagePanel
          class="col-9 m-b-10"
          :uploaded-file="displayedFile"
        />
      </div>
      <div class="row">
        <div v-if="isPdf" class="col-3">
          <PdfVuer :src="displayedFile.base64 ? displayedFile.base64 : displayedFile.pdfPath" />
        </div>
        <div v-else class="col-3">
          <img
            :src="displayedFile.imagePath ? displayedFile.imagePath : displayedFile.fileBase64"
            :alt="displayedFile.originalFile ? displayedFile.originalFile.name : displayedFile.fileName"
            :title="displayedFile.originalFile ? displayedFile.originalFile.name : displayedFile.fileName"
            width="340"
            class="m-b-10"
          />
          <br />
          <DamImageCheckDimensions :file="displayedFile.fileBase64" />
        </div>
        <div class="col-8 text-left">
          <app-textarea
            v-if="!isPdf"
            v-model="displayedFile.description"
            required
            @blur="$v.displayedFiles.$each[index].description.$touch()"
            :error="$v.displayedFiles.$each[index].description.$error"
            :disabled="showExistingImagePanel(displayedFile)"
            id="asset_metadata_description"
            :label="$t('dam.description')"
            :rows="2"
          >
            <Tooltip
              :title="$t('dam.headline_seo_info')"
              icon="fab fa-google"
              customInfoClass="seo"
            />
          </app-textarea>
          <Input
            v-if="!isPdf"
            v-model.trim="displayedFile.imageAltText"
            :required="vlm"
            @blur="$v.displayedFiles.$each[index].imageAltText.$touch()"
            :error="$v.displayedFiles.$each[index].imageAltText.$error && vlm"
            :disabled="showExistingImagePanel(displayedFile)"
            id="asset_metadata_imageAltText"
            :label="$t('dam.imageAltText')"
          />
          <Input
            v-if="isPdf"
            v-model.trim="displayedFile.headline"
            :disabled="showExistingImagePanel(displayedFile)"
            id="asset_metadata_headline"
            :label="$t('dam.headline')"
          />
          <AuthorSearchSelect
            v-model="displayedFile.author"
            @blur="$v.displayedFiles.$each[index].author.$touch()"
            :key="`loaded-file-author-${index}-${forceInputsUpdate}`"
            :error="$v.displayedFiles.$each[index].author.$error"
            :disabled="showExistingImagePanel(displayedFile)"
            :required="!isPdf"
            id="asset_metadata_author"
          >
            <template #afterTitle>
              <Tooltip :title="$t('dam.author_exif_metadata_info')" />
            </template>
          </AuthorSearchSelect>
          <SourceSearchSelect
            v-if="!isPdf"
            v-model="displayedFile.source"
            :disabled="showExistingImagePanel(displayedFile)"
            id="asset_metadata_source"
          />
          <Input
            v-model="displayedFile.keywords"
            :required="!isPdf"
            @blur="$v.displayedFiles.$each[index].keywords.$touch()"
            :error="$v.displayedFiles.$each[index].keywords.$error"
            :disabled="showExistingImagePanel(displayedFile)"
            id="asset_metadata_keywords"
            :label="$t('dam.keywords')"
          >
          </Input>
          <MultiSelect
            v-if="!isPdf"
            :value="displayedFile.siteLocks ? displayedFile.siteLocks.map(siteName => ({ name: siteName, title: $store.getters['site/getSiteTitle'](siteName) })): []"
            @input="(value) => displayedFile.siteLocks = value ? value.map(v => v.name) : []"
            :options="enabledSites"
            :placeholder="$t('dam.filter.restricted_sites_placeholder')"
            label="title"
            track-by="name"
            id="asset_siteLocks"
            data-attr="asset_siteLocks"
          />
          <Select
            v-if="isPdf"
            v-model="displayedFile.categoryCode"
            :label="$t('dam.category')"
            :options="damCategories"
            track-by="id"
            id="categoryCode"
            no-empty-value
          />
        </div>
        <div v-if="!updatingMetadataOfExistingFiles && displayedFiles.length > 1" class="col-1 text-left">
          <button
            @click="removeFile(index)"
            class="btn btn-sm btn-danger m-t-30 m-l-20"
            title="Remove"
          >
            <i class="fas fa-window-close"> </i>
          </button>
        </div>
      </div>
      <hr v-if="index + 1 < displayedFiles.length">
    </div>
  </div>
</template>

<script>
import PdfVuer from 'pdfvuer'
import { mapGetters } from 'vuex'
import DamPdfCategoryMixin from '@/components/mixins/valueObject/DamPdfCategoryMixin'
import DamImageCheckDimensions from '@/components/dam/DamImageCheckDimensions'
import Textarea from '@/components/form/Textarea'
import Tooltip from '@/components/tooltip/Tooltip'
import Input from '@/components/form/inputs/Input'
import SourceSearchSelect from '@/components/author/SourceSearchSelect'
import AuthorSearchSelect from '@/components/author/AuthorSearchSelect'
import MultiSelect from '@/components/form/select/MultiSelect'
import Select from '@/components/form/select/Select'
import UploadExistingImagePanel from '@/components/dam/damUploadModal/UploadExistingImagePanel'
import { required } from 'vuelidate/lib/validators'
import { MODULE_DAM } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'MetadataForm',
  mixins: [DamPdfCategoryMixin],
  props: {
    displayedFiles: {
      type: Array,
      required: true
    },
    updatingMetadataOfExistingFiles: {
      type: Boolean,
      required: true
    },
    forceInputsUpdate: {
      type: Number,
      default: 0
    }
  },
  components: {
    appTextarea: Textarea,
    Tooltip,
    Input,
    MultiSelect,
    Select,
    SourceSearchSelect,
    AuthorSearchSelect,
    DamImageCheckDimensions,
    UploadExistingImagePanel,
    PdfVuer
  },
  validations: {
    displayedFiles: {
      $each: {
        description: {
          required
        },
        imageAltText: {
          required
        },
        author: {
          required
        },
        keywords: {
          required
        }
      }
    }
  },
  computed: {
    ...mapGetters('dam', ['isPdf']),
    ...mapGetters(['vlm']),
    enabledSites () {
      return this.$store.getters['site/enabledSites'](MODULE_DAM)
    }
  },
  methods: {
    showExistingImagePanel (uploadedFile) {
      return this.updatingMetadataOfExistingFiles && !uploadedFile.wasCreated && !uploadedFile.metadataChosen
    },
    removeFile (index) {
      this.$emit('remove-file', index)
    }
  }
}
</script>
